import moment from "moment";

export class Score {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Score";
    this.scene = "Score Update";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.options = options;
    this.data = [];
    let score = this.getScore(options.game);

    this.data.push({
      name: "Sport Type",
      value: {
        text: options.game?.sport_type || "",
      },
    });

    switch (options.type) {
      case "HT":
        this.scene = "HT Score";

        this.data.push({
          name: "Half Text",
          value: { text: "Half Time" },
        });
        break;
      case "FT":
        this.data.push({
          name: "Half Text",
          value: { text: "Full Time" },
        });
        this.scene = "FT Score";
        break;
      case "ETHT":
        this.data.push({
          name: "Half Text",
          value: { text: "ET Half Time" },
        });

        this.scene = "ET HT Score";
        break;
      case "ETFT":
        let caption = "AET";
        let home_score_et =
          options.game?.home_scorers?.filter((goal) => goal.period > 2)
            .length || 0;
        let away_score_et =
          options.game?.away_scorers?.filter((goal) => goal.period > 2)
            .length || 0;
        if (home_score_et > away_score_et) {
          caption =
            options.game?.home_team?.name +
            " win " +
            home_score_et +
            "-" +
            away_score_et +
            " AET";
        } else if (away_score_et > home_score_et) {
          caption =
            options.game?.away_team?.name +
            " win " +
            away_score_et +
            "-" +
            home_score_et +
            " AET";
        }
        this.data.push({
          name: "Half Text",
          value: { text: caption },
        });

        this.scene = "ET FT Score";
        break;
      case "PENS":
        let penalty_caption = "";
        let home_penalties =
          options.game?.home_penalties?.filter((goal) => goal.type > 0)
            .length || 0;
        let away_penalties =
          options.game?.away_penalties?.filter((goal) => goal.type > 0)
            .length || 0;
        if (home_penalties > away_penalties) {
          penalty_caption =
            options.game?.home_team?.name +
            " win " +
            home_penalties +
            "-" +
            away_penalties +
            " on penalties";
        } else if (away_penalties > home_penalties) {
          penalty_caption =
            options.game?.away_team?.name +
            " win " +
            away_penalties +
            "-" +
            home_penalties +
            " on penalties";
        }

        this.data.push({
          name: "Half Text",
          value: { text: penalty_caption },
        });
        this.scene = "Penalties Score";
        break;
      default:
        this.scene = "Score Update";
    }
    this.data.push({
      name: "Stadium",
      value: {
        text:
          options.game?.stadium?.name || options.game?.stadium?.opta_name || "",
      },
    });
    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });

    this.data.push({
      name: "Home Score",
      value: { text: score.home_score || 0 },
    });
    this.data.push({
      name: "Away Score",
      value: { text: score.away_score || 0 },
    });
    this.data.push({
      name: "Home Goals",
      value: {
        text:
          options.game?.home_scorers?.filter((g) => g.type === "goal").length ||
          0,
      },
    });
    this.data.push({
      name: "Away Goals",
      value: {
        text:
          options.game?.away_scorers?.filter((g) => g.type === "goal").length ||
          0,
      },
    });
    this.data.push({
      name: "Home Points",
      value: {
        text:
          (
            options.game?.home_scorers?.filter((g) => g.type === "point")
              ?.length +
            options.game?.home_scorers?.filter((g) => g.type === "2point")
              ?.length *
              2
          ).length
            ?.toString()
            ?.padStart(2, "0") || 0,
      },
    });
    this.data.push({
      name: "Away Points",
      value: {
        text:
          (
            options.game?.away_scorers?.filter((g) => g.type === "point")
              ?.length +
            options.game?.away_scorers?.filter((g) => g.type === "2point")
              ?.length *
              2
          )
            ?.toString()
            ?.padStart(2, "0") || 0,
      },
    });
    this.data.push({
      name: "ET Home Score",
      value: {
        text:
          options.game?.home_scorers?.filter((goal) => goal.period > 2)
            .length || 0,
      },
    });
    this.data.push({
      name: "ET Away Score",
      value: {
        text:
          options.game?.away_scorers?.filter((goal) => goal.period > 2)
            .length || 0,
      },
    });
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: options.game?.competition?.badge || "" },
    });

    let scorer;
    let scorers_sorted = [
      ...(options.game?.home_scorers?.map((s) => {
        return { ...s, team: "home" };
      }) || []),
      ...(options.game?.away_scorers?.map((s) => {
        return { ...s, team: "away" };
      }) || []),
    ];

    scorers_sorted = scorers_sorted.sort((a, b) => b.time - a.time);
    if (scorers_sorted.length > 0) {
      scorer = scorers_sorted[0];
    }

    let scorer_player = options.game.lineup?.home?.find(
      (l) => l.squad?._id === scorer?.player
    );
    if (!scorer_player) {
      scorer_player = options.game.lineup?.away?.find(
        (l) => l.squad?._id === scorer?.player
      );
    }

    if (options.no_scorers) {
      scorer_player = null;
      scorer = null;
    }
    this.data.push({
      name: "Last Goal Player First Name Uppercase",
      value: { text: scorer_player?.squad?.player?.first_name_upper || "" },
    });

    this.data.push({
      name: "Last Goal Player Last Name Uppercase",
      value: { text: scorer_player?.squad?.player?.last_name_upper || "" },
    });

    this.data.push({
      name: "Last Goal Team",
      value: { text: scorer?.team || "" },
    });

    this.data.push({
      name: "Last Goal Penalty",
      value: { text: scorer?.penalty || "" },
    });
    this.data.push({
      name: "Last Goal OG",
      value: { text: scorer?.own_goal || "" },
    });
    let time = moment.duration(scorer?.time);
    let minutes = time?.minutes() + time?.hours() * 60;
    let goalTime = "";
    // minutes += 1;
    if (
      scorer?.period === 2 &&
      minutes > (options?.game?.config?.half_duration || 45) * 2
    ) {
      goalTime =
        (options?.game?.config?.half_duration || 45) * 2 +
        "'+" +
        (minutes - (options?.game?.config?.half_duration || 45) * 2);
    } else if (
      scorer?.period === 1 &&
      minutes > (options?.game?.config?.half_duration || 45)
    ) {
      goalTime =
        (options?.game?.config?.half_duration || 45) +
        "'+" +
        (minutes - (options?.game?.config?.half_duration || 45));
    } else {
      goalTime = minutes + "'";
    }
    if (options.no_scorers) {
      goalTime = "";
    }
    this.data.push({
      name: "Last Goal Time",
      value: { text: goalTime || "" },
    });

    // let scorer;

    // if (options.show_scorers) {
    //   let scorers_sorted = [
    //     ...options.game.live_data.home_scorers.map((s) => {
    //       return { ...s, team: "home" };
    //     }),
    //     ...options.game.live_data.away_scorers.map((s) => {
    //       return { ...s, team: "away" };
    //     }),
    //   ];

    //   scorers_sorted = scorers_sorted.sort((a, b) => b.time - a.time);
    //   if (scorers_sorted.length > 0) {
    //     scorer = scorers_sorted[0];
    //   }
    // }
    // debugger;
    // this.data = [
    //   {
    //     name: "TopTEXT",
    //     value: { text: options.game.competition.name.toUpperCase() },
    //   },
    //   {
    //     name: "Home Team",
    //     value: { text: options.game.home_team.variables["name_uppercase"] },
    //   },
    //   {
    //     name: "Away Team",
    //     value: { text: options.game.away_team.variables["name_uppercase"] },
    //   },
    //   { name: "STADIUM_TEXT", value: { text: options.game.stadium.name } },
    //   options.game.home_team.variables["badge_home"] && {
    //     name: "Home Badge IMG",
    //     value: {
    //       image:
    //         "http://localhost:6060/uploads/MAN CITY/" +
    //         window.encodeURIComponent(
    //           "Badges/" + options.game.home_team.variables["badge_home"]
    //         ),
    //     },
    //   },
    //   options.game.away_team.variables["badge_away"] && {
    //     name: "Away Badge IMG",
    //     value: {
    //       image:
    //         "http://localhost:6060/uploads/MAN CITY/" +
    //         window.encodeURIComponent(
    //           "Badges/" + options.game.away_team.variables["badge_away"]
    //         ),
    //     },
    //   },
    //   {
    //     name: "Home Score",
    //     value: {
    //       text:
    //         (options.game.live_data &&
    //           options.game.live_data.home_team_score) ||
    //         0,
    //     },
    //   },
    //   {
    //     name: "Away Score",
    //     value: {
    //       text:
    //         (options.game.live_data &&
    //           options.game.live_data.away_team_score) ||
    //         0,
    //     },
    //   },
    //   {
    //     name: "VENUE",
    //     value: { text: options.game?.stadium?.name || "" },
    //   },
    //   {
    //     name: "COMMENTATORS",
    //     value: { text: options.game?.commentators || "" },
    //   },
    //   { name: "HALF_TIME", value: { visible: true } },
    //   {
    //     name: "HOME_SCORE_GRP",
    //     value: {
    //       visible:
    //         options.show_scorers && scorer && scorer.team === "home"
    //           ? true
    //           : false,
    //     },
    //   },
    //   {
    //     name: "AWAY_SCORE_GRP",
    //     value: {
    //       visible:
    //         options.show_scorers && scorer && scorer.team === "away"
    //           ? true
    //           : false,
    //     },
    //   },
    // ];

    // if (options.show_scorers && scorer) {
    //   let player = options.game.live_data.home_team_lineup.find(
    //     (l) => l._id === scorer.player
    //   );
    //   if (!player) {
    //     player = options.game.live_data.away_team_lineup.find(
    //       (l) => l._id === scorer.player
    //     );
    //   }
    //   let time = moment.duration(scorer.time);
    //   this.data.push({
    //     name: scorer.team.toUpperCase() + "_SCORER",
    //     value: {
    //       text:
    //         player.first_name[0] +
    //         ". " +
    //         player.last_name +
    //         " " +
    //         (time.minutes() + time.hours() * 60) +
    //         "' " +
    //         (scorer.type ? scorer.type : ""),
    //     },
    //   });
    // }

    this.sendPreview(this.scene, 500, this.data, "IN");
  }

  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    clearTimeout(this.to);
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }

  getScore(game) {
    let goal_points = 3;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "goal").length *
        goal_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "point").length || 0) +
      (game?.home_scorers?.filter((g) => g.type === "2point").length * 2 || 0);

    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "goal").length *
        goal_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "point").length || 0) +
      (game?.away_scorers?.filter((g) => g.type === "2point").length * 2 || 0);

    return {
      home_score,
      away_score,
    };
  }
}
